import React, { Component } from "react";
import Slider from "react-slick";

import axios from "axios";
import URLs from "../utils/apis";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      testimonial_list: [],
    };
  }
  componentDidMount() {
    // this.setState({
    //   testimonial_list: this.props.list,
    //   title: this.props.settings.title,
    // });
  }
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // nextArrow: true,
      // prevArrow: true,
    };
    return (
      <section class="bluemark_testimonial_section">
        <div class="inner-heading">
          <h1>Testimonials</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur. Neque dolor ipsum et arcu
            tortor arcu. Pharetra leo scelerisque fusce nec blandit.
          </p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <Slider {...settings}>
                <div class="slide">
                  <div class="red-block"></div>
                  <img
                    src="./assets/img/testimonial/user.svg"
                    alt=""
                    class="user_img"
                  />
                  <div class="description">
                    <div class="single row">
                      <div class="col-lg-5">
                        <div className="client-sec">
                          <h5>Client Name</h5>
                          <span>Designation & Company</span>
                        </div>
                      </div>
                      <div
                        className="col-lg-7"
                        style={{ position: "relative" }}
                      >
                        <img
                          src="./assets/img/quote.svg"
                          className="quote-img"
                        />
                        <div class="desc">
                          Lorem ipsum dolor sit amet consectetur. Neque dolor
                          ipsum et arcu tortor arcu. Pharetra leo scelerisque
                          fusce nec blandit
                          <br /> Lorem ipsum dolor sit amet consectetur. Neque
                          dolor ipsum et arcu tortor arcu. Pharetra leo
                          scelerisque fusce nec blandit.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide">
                  <div class="red-block"></div>
                  <img
                    src="./assets/img/testimonial/user.svg"
                    alt=""
                    class="user_img"
                  />
                  <div class="description">
                    <div class="single row">
                      <div class="col-lg-5">
                        <div className="client-sec">
                          <h5>Client Name</h5>
                          <span>Designation & Company</span>
                        </div>
                      </div>
                      <div
                        className="col-lg-7"
                        style={{ position: "relative" }}
                      >
                        <img
                          src="./assets/img/quote.svg"
                          className="quote-img"
                        />
                        <div class="desc">
                          Lorem ipsum dolor sit amet consectetur. Neque dolor
                          ipsum et arcu tortor arcu. Pharetra leo scelerisque
                          fusce nec blandit
                          <br /> Lorem ipsum dolor sit amet consectetur. Neque
                          dolor ipsum et arcu tortor arcu. Pharetra leo
                          scelerisque fusce nec blandit.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
