import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import { Spin } from "antd";
import parse from "html-react-parser";

import URLs from "./utils/apis";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

import Slider from "react-slick";

export default class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      author_name: "",
      date: "",
      description: "",

      client_name: "",
      project_name: "",
      image_url: "",

      info_list: [],
      solutions: [],
      statements: [],

      loading: false,
      project_id: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    };
  }
  componentDidMount() {
    if (window.innerWidth > 700) {
      let obj = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
      this.setState({ settings: obj });
    } else {
      let obj = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      this.setState({ settings: obj });
    }
    // const searchParams = new URLSearchParams(window.location.search);
    // const id = searchParams.get("project");
    // if (id) {
    //   this.setState({ project_id: id }, () => this.get_service_list());
    // } else {
    //   window.location.assign("/projects");
    // }
    // this.get_meta_settings();
  }
  // get_service_list = () => {
  //   this.setState({ loading: true });
  //   let params = {
  //     page: "project",
  //   };
  //   axios
  //     .post(URLs.get_project_list, params)
  //     .then((response) => {
  //       if (response.data.status == "1") {
  //         let arr = [];
  //         console.log("pro", response.data.data);
  //         for (let item of response.data.data) {
  //           if (item.p_id == this.state.project_id) {
  //             let url = URLs.image_url + item.image;
  //             this.setState({
  //               client_name: item.client_name,
  //               project_name: item.name,
  //               image_url: url,
  //               description: item.description,
  //               info_list: item.info_data ? JSON.parse(item.info_data) : [],
  //               statements: item.problem_statement
  //                 ? JSON.parse(item.problem_statement)
  //                 : [],
  //               solutions: item.solution_results
  //                 ? JSON.parse(item.solution_results)
  //                 : [],
  //               // author_name: item.author,
  //               loading: false,
  //             });
  //             return;
  //           }
  //         }
  //         this.setState({
  //           loading: false,
  //         });
  //       } else {
  //         this.setState({ loading: false });
  //         return;
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ loading: false });
  //     });
  // };
  // get_meta_settings = () => {
  //   this.setState({ loading: true });
  //   axios
  //     .get(URLs.get_meta_settings)
  //     .then((response) => {
  //       if (response.data.status == "1") {
  //         let currentObj = response.data.data;
  //         let title = "";
  //         let description = "";
  //         let keywords = "";

  //         for (let item of response.data.data) {
  //           if (item.page == "projectdetails") {
  //             title = item.title;
  //             description = item.description;
  //             keywords = item.keywords;
  //           }
  //         }
  //         this.setState({
  //           meta_title: title,
  //           meta_description: description,
  //           meta_keywords: keywords,
  //           loading: false,
  //         });
  //       } else {
  //         this.setState({ loading: false });
  //         return;
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ loading: false });
  //       console.error("Warning:", error);
  //     });
  // };
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,

      // nextArrow: true,
      // prevArrow: true,
    };
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        <div class="top-space"></div>
        {/* Start blog details section  */}
        <div class="main_heading product">
          <h1>Product 1</h1>
          <div className="line"></div>
        </div>
        <section class="bluemark_product_details_section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6">
                <div class="image_section">
                  <div class="carousel carousel-main" data-flickity>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                  </div>

                  <div
                    class="carousel carousel-nav"
                    data-flickity='{ "asNavFor": ".carousel-main", "contain": true, "pageDots": false }'
                  >
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                    <div class="carousel-cell"></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <h3>Product 01</h3>
                <p class="short_desc">
                  Lorem ipsum dolor sit amet consectetur. Amet tristique porta
                  suspendisse tortor aliquet ornare. Venenatis lectus
                  pellentesque magna purus suspendisse. Vulputate eu odio cursus
                  massa nunc sed. Lectus ut augue ante gravida habitant et
                  molestie.
                </p>
                <h5 class="p_head">MOQ</h5>
                <p class="p_desc">100</p>

                <h5 class="p_head">Variations</h5>
                <p class="p_desc">
                  Variation 01 ( Variation Details ) Variation 02 ( Variation
                  Details ) Variation 03 ( Variation Details )
                </p>

                <div class="button_list">
                  <button class="bluemark_button blue">Check Price</button>
                  <button class="bluemark_button red">Buy on Amazon</button>
                </div>

                <h5 class="p_head">Product Details</h5>
                <p class="p_desc">
                  Lorem ipsum dolor sit amet consectetur. Amet tristique porta
                  suspendisse tortor aliquet ornare. Venenatis lectus
                  pellentesque magna purus suspendisse. Vulputate eu odio cursus
                  massa nunc sed. Lectus ut augue ante gravida habitant et
                  molestie.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_similar_products">
          <h2>Similar Products</h2>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <Slider {...this.state.settings}>
                  <div class="single">
                    <div className="product_highlight"></div>
                    <div class="product_name">
                      <span>Name</span>
                    </div>
                  </div>
                  <div class="single">
                    <div className="product_highlight"></div>
                    <div class="product_name">
                      <span>Name</span>
                    </div>
                  </div>
                  <div class="single">
                    <div className="product_highlight"></div>
                    <div class="product_name">
                      <span>Name</span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* End blog details section  */}
        <Footer />
      </div>
    );
  }
}
