import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class OtherSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      records: [],
      title: "",
      sub_title: "",
      description: "",
      button_text: "",
      button_link: "",
      is_section_visible: "",
    };
  }
  componentDidMount() {
    // this.setState({
    //   title: this.props.setting.title,
    //   sub_title: this.props.setting.sub_title,
    //   description: this.props.setting.description,
    //   button_text: this.props.setting.button_text,
    //   button_link: this.props.setting.button_link,
    //   is_section_visible: this.props.setting.is_section_visible,
    // });
  }
  render() {
    return (
      <section class="bluemark_ctc_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 m-auto">
              <div class="block">
                <h3>JOIN US AT BLUEMARK</h3>
                <h5> Check our latest job opportunities</h5>
                <a href="#" className="bluemark_button">
                  Apply Now &nbsp;&nbsp;&nbsp;
                  <box-icon name="right-arrow-alt" color="#fff"></box-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
