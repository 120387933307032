import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Testimonials from "./components/Testimonials";

import WhoWeAre from "./components/WhoWeAre";
import Companies from "./components/Companies";
import TopSec from "./components/TopSec";
import TeamList from "./components/TeamList";
import Blog from "./components/Blog";
import { Helmet } from "react-helmet";

import Ctc from "./components/Ctc";

import URLs from "./utils/apis";
import { message, Spin, Modal, Button } from "antd";
import HeroModal from "./components/HeroModal";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hero_title: "",
      hero_description: "",
      input_placeholder: "",
      hero_img: "",
      search_text: "",
      hero_img_url: "",
      service_title: "",
      service_description: "",

      project_title: "",
      project_description: "",
      formLoader: false,
      email: "",
      project_list: [],
      title: "",
      description: "",
      keywords: "",

      testimonial_list: [],
      testimonial_settings: [],

      client_list: [],
      client_setting: [],

      who_list: [],

      team_list: [],
      team_setting: [],

      blog_list: [],
      blog_setting: [],
      ctc_setting: {},

      modal_status: false,
    };
  }
  componentDidMount() {
    this.get_home_page_settings();
  }
  get_home_page_settings = () => {
    axios
      .get(URLs.get_home_page_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let url = URLs.image_url + currentObj.hero_setting.hero_image;
          this.setState({
            title: currentObj.meta_setting[0].title,
            description: currentObj.meta_setting[0].description,
            keywords: currentObj.meta_setting[0].keywords,
            hero_title: currentObj.hero_setting.title,
            hero_description: currentObj.hero_setting.description,
            input_placeholder: currentObj.hero_setting.input_placeholder,
            hero_img_url: url,
            hero_img: currentObj.hero_setting.hero_image,

            service_title: currentObj.service_setting.title,
            service_description: currentObj.service_setting.description,

            service_list: currentObj.service_list,
            project_list: currentObj.project_list,

            project_title: currentObj.project_settings.title,
            project_description: currentObj.project_settings.description,

            testimonial_list: currentObj.testimonial_list,
            testimonial_settings: currentObj.testimonial_settings,

            client_list: currentObj.client_list,
            client_setting: currentObj.client_setting,

            who_list: currentObj.who_list,

            team_list: currentObj.team_list,
            team_setting: currentObj.team_setting,

            blog_list: currentObj.blog_list,
            blog_setting: currentObj.blog_setting,

            ctc_setting: currentObj.ctc_setting,

            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000, // Speed in milliseconds
    };
    return (
      <div className="page-container">
        {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        <div class="top-space"></div>
        <section class="blumemark_home_hero_section">
          <Slider {...settings}>
            <div className="slide">
              <a href="https://www.google.com/" target="_blank">
                <div
                  class="single"
                  style={{
                    background: `url('./assets/img/home/slider-img.png')`,
                  }}
                ></div>
              </a>
            </div>
            <div className="slide">
              <a href="https://www.google.com/" target="_blank">
                <div
                  class="single"
                  style={{
                    background: `url('./assets/img/home/slider-img.png')`,
                  }}
                ></div>
              </a>
            </div>
          </Slider>
          <section className="tilt_block"></section>
        </section>

        {/* <!-- about --> */}
        <section class="bluemark_about_section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <img
                  src="./assets/img/about-us/about.png"
                  className="left-img"
                  alt=""
                />
              </div>
              <div class="col-lg-6">
                <div class="right">
                  <h2>ABOUT US</h2>
                  <div className="line"></div>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Neque dolor ipsum et
                    arcu tortor arcu. Pharetra leo scelerisque fusce nec
                    blandit. Nulla nulla ullamcorper id proin purus magna
                    suspendisse praesent. Eros vel sed dolor vulputate fames
                    ligula bibendum in convallis.
                  </p>
                  <a href="#" className="bluemark_button">
                    Know More
                  </a>
                  <div class="counts">
                    <div class="row">
                      <div class="col-lg-4" style={{ width: 150 }}>
                        <div class="single">
                          <img
                            src="./assets/img/about-us/counts/founded.svg"
                            alt=""
                          />
                          <h4>2018</h4>
                          <small>Founded</small>
                        </div>
                      </div>
                      <div class="col-lg-4" style={{ width: 150 }}>
                        <div class="single text-center">
                          <img
                            src="./assets/img/about-us/counts/employee.svg"
                            alt=""
                          />
                          <h4>20</h4>
                          <small>Employee</small>
                        </div>
                      </div>
                      <div class="col-lg-4" style={{ width: 150 }}>
                        <div class="single">
                          {/* <div className="img_wrapper"> */}
                          <img
                            src="./assets/img/about-us/counts/clients.svg"
                            alt=""
                          />
                          {/* </div> */}
                          <h4>200+</h4>
                          <small>Satisfied Clients</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_category_section">
          <div class="wrap">
            <div class="card">
              <div class="flex row structure">
                <div class="flex row structure-1">
                  <div class="flex element b">
                    <div class="content">Category 01 </div>
                  </div>
                  <div class="flex element a">
                    <div class="content">Category 03</div>
                  </div>
                  <div class="flex col structure-1-2-1">
                    <div class="flex element c">
                      <div class="content">Category 02</div>
                    </div>
                  </div>
                  <div class="flex element e">
                    <div class="content">Category 04</div>
                  </div>
                </div>
                <div class="flex row structure-2">
                  <div class="flex element f">
                    <div class="content">Category 07</div>
                  </div>
                  <div class="flex col structure-2-2">
                    <div class="flex element h">
                      <div class="content">Category 08</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="category_1"></div>
          <div class="category_3"></div>
          <div class="category_7"></div>
          <div class="category_2"></div>
          <div class="category_4"></div>
          <div class="category_5"></div>
          <div class="category_6"></div>
          <div class="category_8"></div> */}
        </section>

        <section class="bluemark_why_choose_section">
          <div class="inner-heading">
            <h2>Why People Choose Us</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur. Neque dolor ipsum et arcu
              tortor arcu. Pharetra leo scelerisque fusce nec blandit.
            </p>
          </div>
          <div class="container list">
            <div class="row">
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
              <div class="col-lg-4 single">
                <div class="circle"></div>
                <h5>Text 1</h5>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_industries_served_section">
          <div class="container">
            <h2>Industries we Served</h2>
            <div class="row list">
              <div class="col-lg-3">
                <div class="single">
                  <h3>Industry</h3>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="single">
                  <h3>Industry</h3>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="single">
                  <h3>Industry</h3>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="single">
                  <h3>Industry</h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_trusted_by_section">
          <h2>Trusted by</h2>
          <marquee direction="left" vspace={0} hspace={"0px"}>
            <div class="list">
              <div class="logo">
                <img src="./assets/img/clients/adobe.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/microsoft.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/google.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/larsen.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/zoho.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/adobe.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/microsoft.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/google.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/larsen.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/zoho.png" alt="" />
              </div>
            </div>
          </marquee>
          <marquee style={{ marginTop: 24 }}>
            <div class="list" style={{ position: "relative", left: -40 }}>
              <div class="logo">
                <img src="./assets/img/clients/adobe.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/microsoft.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/google.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/larsen.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/zoho.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/adobe.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/microsoft.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/google.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/larsen.png" alt="" />
              </div>
              <div class="logo">
                <img src="./assets/img/clients/zoho.png" alt="" />
              </div>
            </div>
          </marquee>
        </section>

        <Testimonials />

        <Ctc />
        {this.state.blog_list.length > 0 && (
          <Blog list={this.state.blog_list} setting={this.state.blog_setting} />
        )}
        <Footer />
      </div>
    );
  }
}
