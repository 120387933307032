import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import { Spin } from "antd";
import parse from "html-react-parser";

import URLs from "./utils/apis";
import axios from "axios";
import moment from "moment";
import { Helmet } from "react-helmet";

export default class PostDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      author_name: "",
      date: "",
      description: "",
      image_url: "",

      loading: false,
      blog_id: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get("post");
    console.log("post:", id); // Output the retrieved ID
    if (id) {
      this.setState({ blog_id: id }, () => this.get_blog_list());
    } else {
      window.location.assign("/blog");
    }
    this.get_meta_settings();
  }
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "blogdetails") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_blog_list = () => {
    let params = {
      page: "blog",
    };
    this.setState({ loading: true });
    axios
      .post(URLs.get_blog_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          for (let item of response.data.data) {
            if (item.post_id == this.state.blog_id) {
              let date_string = moment(item.date).format("MMMM DD, YYYY");
              let url = URLs.image_url + item.banner_img;
              this.setState({
                title: item.title,
                date: date_string,
                image_url: url,
                description: JSON.parse(item.description),
                author_name: item.author,
                loading: false,
              });
              return;
            }
          }
          this.setState({
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        <div class="top-space"></div>
        {/* Start blog details section  */}
        <Spin spinning={this.state.loading}>
          <section class="post-details">
            <div class="heading-section" style={{ maxWidth: "85%" }}>
              <span>Blog</span>
              <h1>{this.state.title}</h1>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="single-post">
                    <div class="timestamp">
                      {this.state.author_name && (
                        <span>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              position: "relative",
                              top: -1,
                              marginRight: 5,
                            }}
                          >
                            <path
                              d="M16.3337 3.83341H14.667V3.00008C14.667 2.77907 14.5792 2.56711 14.4229 2.41083C14.2666 2.25455 14.0547 2.16675 13.8337 2.16675C13.6126 2.16675 13.4007 2.25455 13.2444 2.41083C13.0881 2.56711 13.0003 2.77907 13.0003 3.00008V3.83341H8.00033V3.00008C8.00033 2.77907 7.91253 2.56711 7.75625 2.41083C7.59997 2.25455 7.38801 2.16675 7.16699 2.16675C6.94598 2.16675 6.73402 2.25455 6.57774 2.41083C6.42146 2.56711 6.33366 2.77907 6.33366 3.00008V3.83341H4.66699C4.00395 3.83341 3.36807 4.09681 2.89923 4.56565C2.43038 5.03449 2.16699 5.67037 2.16699 6.33341V16.3334C2.16699 16.9965 2.43038 17.6323 2.89923 18.1012C3.36807 18.57 4.00395 18.8334 4.66699 18.8334H16.3337C16.9967 18.8334 17.6326 18.57 18.1014 18.1012C18.5703 17.6323 18.8337 16.9965 18.8337 16.3334V6.33341C18.8337 5.67037 18.5703 5.03449 18.1014 4.56565C17.6326 4.09681 16.9967 3.83341 16.3337 3.83341ZM17.167 16.3334C17.167 16.5544 17.0792 16.7664 16.9229 16.9227C16.7666 17.079 16.5547 17.1667 16.3337 17.1667H4.66699C4.44598 17.1667 4.23402 17.079 4.07774 16.9227C3.92146 16.7664 3.83366 16.5544 3.83366 16.3334V10.5001H17.167V16.3334ZM17.167 8.83341H3.83366V6.33341C3.83366 6.1124 3.92146 5.90044 4.07774 5.74416C4.23402 5.58788 4.44598 5.50008 4.66699 5.50008H6.33366V6.33341C6.33366 6.55443 6.42146 6.76639 6.57774 6.92267C6.73402 7.07895 6.94598 7.16675 7.16699 7.16675C7.38801 7.16675 7.59997 7.07895 7.75625 6.92267C7.91253 6.76639 8.00033 6.55443 8.00033 6.33341V5.50008H13.0003V6.33341C13.0003 6.55443 13.0881 6.76639 13.2444 6.92267C13.4007 7.07895 13.6126 7.16675 13.8337 7.16675C14.0547 7.16675 14.2666 7.07895 14.4229 6.92267C14.5792 6.76639 14.667 6.55443 14.667 6.33341V5.50008H16.3337C16.5547 5.50008 16.7666 5.58788 16.9229 5.74416C17.0792 5.90044 17.167 6.1124 17.167 6.33341V8.83341Z"
                              fill="#263F7B"
                            />
                          </svg>{" "}
                          {this.state.date} |{" "}
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              position: "relative",
                              top: -1,
                              marginRight: 5,
                            }}
                          >
                            <path
                              d="M10.4997 3.83325C11.3837 3.83325 12.2316 4.18444 12.8567 4.80956C13.4818 5.43468 13.833 6.28253 13.833 7.16659C13.833 8.05064 13.4818 8.89849 12.8567 9.52361C12.2316 10.1487 11.3837 10.4999 10.4997 10.4999C9.61562 10.4999 8.76777 10.1487 8.14265 9.52361C7.51753 8.89849 7.16634 8.05064 7.16634 7.16659C7.16634 6.28253 7.51753 5.43468 8.14265 4.80956C8.76777 4.18444 9.61562 3.83325 10.4997 3.83325ZM10.4997 12.1666C14.183 12.1666 17.1663 13.6583 17.1663 15.4999V17.1666H3.83301V15.4999C3.83301 13.6583 6.81634 12.1666 10.4997 12.1666Z"
                              fill="#263F7B"
                            />
                          </svg>
                          {this.state.author_name}
                        </span>
                      )}
                    </div>
                    {this.state.image_url && <img src={this.state.image_url} />}
                    <div class="description">
                      {parse(this.state.description)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Spin>

        {/* End blog details section  */}
        <Footer />
      </div>
    );
  }
}
