import React, { Component } from "react";

export default class Menu extends Component {
  render() {
    return (
      <ul>
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <a href="/products">Products</a>
        </li>
        <li>
          <a href="/about-us">About us</a>
        </li>
        <li>
          <a href="/career">Career</a>
        </li>
        <li>
          <a href="/contact-us">Contact us</a>
        </li>
      </ul>
    );
  }
}
