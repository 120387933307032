import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import URLs from "../utils/apis";
import { Spin } from "antd";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollOffset: 0,
      service_list: [],
      category_list: [
        {
          label: "Category 01",
          id: 1,
        },
        {
          label: "Category 02",
          id: 1,
        },
        {
          label: "Category 03",
          id: 1,
        },
        {
          label: "Category 04",
          id: 1,
        },
        {
          label: "Category 05",
          id: 1,
        },
      ],
      theme_list: [
        {
          label: "Theme 1",
          id: 1,
        },
        {
          label: "Theme 2",
          id: 1,
        },
        {
          label: "Theme 3",
          id: 1,
        },
        {
          label: "Theme 4",
          id: 1,
        },
      ],
      records: [],
      footer_logo_link: "",
      social_links: [],
      address:
        "Plot No. 16/4, Ramtekdi Industrial Estate, Opp. Ajanta Soap Company, Hadapsar, Pune - 411013 (MH) India.",
      loading: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    const scrollOffset = window.pageYOffset;
    if (scrollOffset > 1500) {
      $("#back-to-top-button").removeClass("d-none");
    } else {
      $("#back-to-top-button").addClass("d-none");
    }
  }
  componentDidMount() {
    // this.get_footer_settings();
  }
  get_footer_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_footer_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let footer_logo_link =
            URLs.image_url + currentObj.header_footer_settings.footer_logo;
          this.setState({
            social_links: currentObj.social_list,
            footer_logo_link: footer_logo_link,
            address: currentObj.header_footer_settings.footer_address,
            service_list: currentObj.service_list,
            records: currentObj.blog_posts,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-sm-12 col-12">
                {/* {this.state.footer_logo_link && this.state.footer_logo_link && ( */}
                <img
                  className="logo"
                  src={"./assets/img/logo.svg"}
                  className="logo"
                  alt=""
                />
                {/*)} */}
                <p className="text-center">{this.state.address}</p>
                <div className="social-icons">
                  <ul>
                    {this.state.social_links &&
                      this.state.social_links.map((item, index) => {
                        let url = URLs.image_url + item.icon_image;
                        return (
                          <li key={index}>
                            <a href={item.social_link} target="_blank">
                              <img src={url} />
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="link-list">
                  <ul>
                    <li>
                      <a href="/" className="bold">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/blog" className="bold">
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href="/career" className="bold">
                        Career
                      </a>
                    </li>
                    <li>
                      <a href="/about-us" className="bold">
                        About us
                      </a>
                    </li>
                    <li>
                      <a href="/contact" className="bold">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="link-list">
                  <h5>Top Categories</h5>
                  <ul>
                    {this.state.category_list.map((item, index) => {
                      if (index < 5) {
                        return (
                          <li key={index}>
                            <a href={`/service-details?service=${item.id}`}>
                              {item.label}
                            </a>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="link-list">
                  <h5>Themes</h5>
                  <ul>
                    {this.state.theme_list &&
                      this.state.theme_list.map((item, index) => {
                        return (
                          <li key={index}>
                            <a href={`/blog-details?post=${item.id}`}>
                              {item.label}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="small_footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <p>©Bluemark Promotions LLP</p>
              </div>
              <div className="col-lg-6">
                <p className="text-to-right">Designed and Developed by</p>
              </div>
            </div>
          </div>
        </div>
        <a
          href="#top-div"
          className="back-to-top-button d-none"
          id="back-to-top-button"
        >
          <box-icon
            name="chevron-up"
            color="#fff"
            size={"40px"}
            style={{ fontWeight: "800" }}
          ></box-icon>
        </a>
      </Spin>
    );
  }
}
