import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import Ctc from "./components/Ctc";
import WhoWeAre from "./components/WhoWeAre";
import Companies from "./components/Companies";
import Slider from "react-slick";

import URLs from "./utils/apis";
import axios from "axios";
import { Helmet } from "react-helmet";

export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],

      countdown_title: "",
      founded_count: 0,
      project_count: 0,
      employee_count: 0,
      clients_count: 0,
      story_link: "",
      our_story_title: "",

      who_list: [],

      client_list: [],
      client_setting: [],

      cta_setting: {},

      title: "",
      description: "",
      keywords: "",
      about_us_list: [
        {
          num: "01",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
        {
          num: "02",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
        {
          num: "03",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
        {
          num: "04",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
        {
          num: "05",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
        {
          num: "06",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries. ",
        },
      ],
      trusted_by_list: [
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
        {
          label: "Injection molding",
          title: "One of India’s most trusted",
          desc: "one of India’s most trusted writing instrument manufacturers with a national and international presence in over 50 countries.",
        },
      ],
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    };
  }
  componentDidMount() {
    // this.get_about_settings();
    if (window.innerWidth > 700) {
      let obj = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
      };
      this.setState({ settings: obj });
    } else {
      let obj = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
      this.setState({ settings: obj });
    }
  }
  // get_about_settings = () => {
  //   this.setState({ loading: true });
  //   axios
  //     .get(URLs.get_about_page_settings)
  //     .then((response) => {
  //       if (response.data.status == "1") {
  //         let item = response.data.data;
  //         this.setState({
  //           records: item.mission_list,

  //           countdown_title: item.countdown_setting.heading,
  //           founded_count: item.countdown_setting.founded,
  //           project_count: item.countdown_setting.projects,
  //           employee_count: item.countdown_setting.employee,
  //           clients_count: item.countdown_setting.satisfied_clients,
  //           story_link: item.countdown_setting.story_link,
  //           our_story_title: item.countdown_setting.our_story_title,

  //           who_list: item.who_list,

  //           client_list: item.client_list,
  //           client_setting: item.client_setting,

  //           cta_setting: item.cta_setting,

  //           title: item.meta_setting[0].title ? item.meta_setting[0].title : "",
  //           description: item.meta_setting[0].description
  //             ? item.meta_setting[0].description
  //             : "",
  //           keywords: item.meta_setting[0].keywords
  //             ? item.meta_setting[0].keywords
  //             : "",
  //         });
  //       } else {
  //         this.setState({ loading: false });
  //         return;
  //       }
  //     })
  //     .catch((error) => {
  //       this.setState({ loading: false });
  //       console.error("Warning:", error);
  //     });
  // };
  render() {
    return (
      <div className="page-container">
        {/* {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )} */}
        <Header />
        <TopSec />
        <div class="top-space"></div>

        <section class="blumemark_about_hero_section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="content">
                  <div class="inner">
                    <h2>About us</h2>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_about_desc">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <p>
                  one of India’s most trusted writing instrument manufacturers
                  with a national and international presence in over 50
                  countries. We boast of a strong and extensive network in South
                  East Asia, Middle East, USA, UK, Europe, South America,
                  Africa, Russia and CIS countries. The organisation constantly
                  endeavours to bring out new and innovative products and
                  packaging and has an extensive and diverse range of products
                  catering an assortment of ball pens, gel pens, roller pens,
                  retractable ball pens, dark pencils and other stationery.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_chairman_message_section">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6">
                <img src="./assets/img/writing_instrument.svg" alt="" />
              </div>
              <div class="col-lg-6">
                <small>Chairman’s Message</small>
                <h3>
                  One Of India’s Leading <br />
                  Manufacturer Of Writing <br />
                  Instruments
                </h3>
                <p>
                  One of India’s most trusted writing instrument manufacturers
                  with a national and international presence in over 50
                  countries. We boast of a strong and extensive network in South
                  East Asia, Middle East, USA, UK, Europe, South America,
                  Africa, Russia and CIS countries. The organisation constantly
                  endeavours to bring out new and innovative products and
                  packaging and has an extensive and diverse range of products
                  catering an assortment of ball pens, gel pens, roller pens,
                  retractable ball pens, dark pencils and other stationery.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_mission_vission_section">
          <div class="single_topic">
            <div className="row">
              <div className="col-lg-8">
                <div class="desc">
                  <h3>Vision</h3>
                  <p>
                    To empower poeple to express themselves better and create a
                    happier world by providing innovative, quality and
                    affordable solutions, coveniently.
                  </p>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2">
                <div class="img">
                  <img src="./assets/img/mission_vission/flag.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="red-line"></div>
          </div>
          <div class="single_topic right">
            <div className="row">
              <div className="col-lg-2">
                <div class="img">
                  <img src="./assets/img/mission_vission/mission.svg" alt="" />
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div class="desc">
                  <h3>Mission</h3>
                  <p>
                    To empower poeple to express themselves better and create a
                    happier world by providing innovative, quality and
                    affordable solutions, coveniently.
                  </p>
                </div>
              </div>
            </div>
            <div class="red-line"></div>
          </div>
          <div class="single_topic">
            <div className="row">
              <div className="col-lg-8">
                <div class="desc">
                  <h3>Core Beliefs</h3>
                  <p>
                    To empower poeple to express themselves better and create a
                    happier world by providing innovative, quality and
                    affordable solutions, coveniently.
                  </p>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2">
                <div class="img">
                  <img
                    src="./assets/img/mission_vission/core-belief.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="red-line"></div>
          </div>
        </section>

        <section class="bluemark_infrastructure_section">
          <div class="our_infrastruction_block">
            <h1>Our Infrastructure</h1>
            <h6>
              one of India’s most trusted writing instrument manufacturers with
              a national and international presence in over 50 countries.
            </h6>
          </div>
          <div class="right_img">
            <img src="./assets/img/our-infra.svg" alt="" />
          </div>
        </section>

        <section class="bluemark_trusted_section">
          <div class="container">
            <div class="row">
              {this.state.trusted_by_list.map((item, index) => {
                return (
                  <div class="col-lg-6" key={index}>
                    <div class="item">
                      <div class="left">
                        <span>{item.label}</span>
                        <div class="line"></div>
                        <h5>{item.title}</h5>
                        <p>{item.desc}</p>
                      </div>
                      <div class="right">
                        <img src="./assets/img/trust-img.svg" alt="" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <div className="about_heading">
          <h2>About us</h2>
          <div class="line"></div>
        </div>
        <section class="bluemark_about_point_section">
          <div class="container">
            <div class="row">
              {this.state.about_us_list.map((item, index) => {
                return (
                  <div class="col-lg-4" key={index}>
                    <div class="item">
                      <div class="num"> {item.num}</div>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section class="bluemark_leadership_team_section">
          <h2>Leadership Team</h2>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <Slider {...this.state.settings}>
                  <div class="single">
                    <img src="./assets/img/team/member-2.svg" />
                    <div class="content">
                      <h5>Mr. Abcd XYZ</h5>
                      <small>Designation</small>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Neque dolor
                        ipsum et arcu tortor arcu. Pharetra leo scelerisque
                        fusce nec blandit. Nulla nulla ullamcorper id proin
                        purus magna suspendisse praesent. Eros vel sed dolor
                        vulputate fames ligula bibendum in convallis.Lorem ipsum
                        dolor sit amet consectetur. Neque dolor ipsum et arcu
                        tortor arcu. Pharetra leo scelerisque fusce nec blandit.
                        Nulla nulla ullamcorper id proin purus magna suspendisse
                        praesent. Eros vel sed dolor vulputate fames ligula
                        bibendum in convallis.
                      </p>
                    </div>
                  </div>
                  <div class="single">
                    <img src="./assets/img/team/member-2.svg" />
                    <div class="content">
                      <h5>Mr. Abcd XYZ</h5>
                      <small>Designation</small>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Neque dolor
                        ipsum et arcu tortor arcu. Pharetra leo scelerisque
                        fusce nec blandit. Nulla nulla ullamcorper id proin
                        purus magna suspendisse praesent. Eros vel sed dolor
                        vulputate fames ligula bibendum in convallis.Lorem ipsum
                        dolor sit amet consectetur. Neque dolor ipsum et arcu
                        tortor arcu. Pharetra leo scelerisque fusce nec blandit.
                        Nulla nulla ullamcorper id proin purus magna suspendisse
                        praesent. Eros vel sed dolor vulputate fames ligula
                        bibendum in convallis.
                      </p>
                    </div>
                  </div>
                  <div class="single">
                    <img src="./assets/img/team/member-2.svg" />
                    <div class="content">
                      <h5>Mr. Abcd XYZ</h5>
                      <small>Designation</small>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Neque dolor
                        ipsum et arcu tortor arcu. Pharetra leo scelerisque
                        fusce nec blandit. Nulla nulla ullamcorper id proin
                        purus magna suspendisse praesent. Eros vel sed dolor
                        vulputate fames ligula bibendum in convallis.Lorem ipsum
                        dolor sit amet consectetur. Neque dolor ipsum et arcu
                        tortor arcu. Pharetra leo scelerisque fusce nec blandit.
                        Nulla nulla ullamcorper id proin purus magna suspendisse
                        praesent. Eros vel sed dolor vulputate fames ligula
                        bibendum in convallis.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {this.state.cta_setting && <Ctc setting={this.state.cta_setting} />}
        <Footer />
      </div>
    );
  }
}
