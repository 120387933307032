import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import { Modal } from "antd";

export default class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_status: false,
      list: ["one", "two", "three", "four"],
    };
  }
  open_modal = () => {
    this.setState({ modal_status: true });
  };
  close_modal = () => {
    this.setState({ modal_status: false });
  };
  render() {
    return (
      <div className="page-container">
        <Header />
        <TopSec />
        <div className="top-space"></div>
        <div className="main_heading">
          <h1>Career</h1>
        </div>

        <div className="bluemark_job_openings_section">
          <div className="red-line"></div>
          <div className="container-fluid">
            <h2>Latest Job Openings</h2>
            <div className="row">
              {this.state.list.map((item, index) => {
                return (
                  <div className="col-lg-6" key={index}>
                    <div className="single">
                      <h4>Junior Graphic Designer</h4>
                      <small>Posted Before 2 Days</small>
                      <h6>Job Details</h6>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Accumsan diam
                        eget aliquet tortor ege stas semper. Sit consectetur
                        mattis at nibh eleifend sed posuere donec ornare. Eget
                        ac vulputate tristique et risus amet quis facilisis. In
                        pulvinar sed et enim fermentum viverra auctor enim.
                      </p>
                      <div className="button_list">
                        <a href="#">
                          Apply via Linkedin{" "}
                          <box-icon
                            type="logo"
                            color="#fff"
                            name="linkedin-square"
                            style={{
                              position: "relative",
                              top: 0,
                              left: 4,
                            }}
                          ></box-icon>
                        </a>
                        <a href="#!" onClick={() => this.open_modal()}>
                          Apply Now &nbsp;
                          <img
                            src="./assets/img/apply-icon.svg"
                            style={{
                              position: "relative",
                              top: -1,
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />

        <Modal
          open={this.state.modal_status}
          footer={[]}
          width={1000}
          onCancel={() => this.close_modal()}
        >
          <div className="job_popup">
            <h4>Job Title</h4>
            <small>Posted Before 2 Days</small>
            <h6>Job Details</h6>
            <p>
              Lorem ipsum dolor sit amet consectetur. Accumsan diam eget aliquet
              tortor egestas semper. Sit consectetur mattis at nibh eleifend sed
              posuere donec ornare. Eget ac vulputate tristique et risus amet
              quis facilisis. In pulvinar sed et enim fermentum viverra auctor
              enim.
            </p>
            <div className="form row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="">Name*</label>
                  <input type="text" placeholder="Enter Your Full Name" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="">Upload your resume/portfolio*</label>
                  <input
                    type="text"
                    placeholder="Attach multiple Documents ( Resume/CV/Cover Letter etc..)"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="">Email*</label>
                  <input type="text" placeholder="Email Address" />
                </div>
                <div className="form-group">
                  <label for="">Mobile Number*</label>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    style={{ marginBottom: 0 }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="">Note</label>
                  <textarea
                    name=""
                    id=""
                    placeholder="Message"
                    rows="5"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-lg-6 m-auto">
              <button type="button" className="bluemark_button">
                Apply Now &nbsp;
                <box-icon name="right-arrow-alt" color="#fff"></box-icon>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
