import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./app/Home";
import Contact from "./app/Contact";
import About from "./app/About";
import Blog from "./app/Blog";
import PostDetails from "./app/PostDetails";
import Products from "./app/Products";
import ProductDetails from "./app/ProductDetails";
import Career from "./app/Career";
import SignIn from "./app/SignIn";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<PostDetails />} />
          <Route path="/signin" element={<SignIn />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
