import React, { Component } from "react";
import axios from "axios";
import URLs from "../utils/apis";

export default class OtherSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoader: false,
      records: [],
      title: "",
      sub_title: "",
      description: "",
      button_text: "",
      button_link: "",
      is_section_visible: "",
    };
  }
  componentDidMount() {
    this.get_client_list();
  }
  get_client_list = () => {
    axios
      .get(URLs.get_cta_list)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            title: currentObj.title,
            sub_title: currentObj.sub_title,
            description: currentObj.description,
            button_text: currentObj.button_text,
            button_link: currentObj.button_link,
            is_section_visible: currentObj.is_section_visible,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    // if (this.state.is_section_visible == "1") {
    return (
      <div class="bluemark_other_section">
        <div class="inner">
          <h2>Looking for something else?</h2>
          <a href={"#"} target="_blank" className="bluemark_button">
            Let’s Discuss &nbsp;
            <box-icon name="right-arrow-alt" color="#012f64"></box-icon>
          </a>
        </div>
        <div className="red-line"></div>
      </div>
    );
    // } else {
    //   return "";
    // }
  }
}
