import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import OtherSection from "./components/OtherSection";

import URLs from "./utils/apis";
import { Helmet } from "react-helmet";
import { Spin, Checkbox } from "antd";

import axios from "axios";

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "",
      project_list: [],
      copy_project_list: [],
      project_title: "",
      project_description: "",
      show_button_status: false,
      title: "",
      description: "",
      keywords: "",
      categories: [
        "category 1",
        "category 2",
        "category 3",
        "category 4",
        "category 5",
        "category 6",
        "category 7",
        "category 8",
      ],
      product_list: [
        "Product 1",
        "Product 2",
        "Product 3",
        "Product 4",
        "Product 5",
        "Product 6",
      ],
    };
  }
  componentDidMount() {
    this.get_project_list();
    this.get_meta_settings();
  }
  get_project_list = () => {
    this.setState({ loading: true });
    let params = {
      page: "projects",
    };
    axios
      .post(URLs.get_project_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          let index = 0;
          let newArr = [];
          for (let item of response.data.data) {
            if (index < 4) {
              newArr.push(item);
            }
          }
          this.setState({
            project_list: newArr,
            copy_project_list: response.data.data,
            show_button_status: response.data.data.length > 4 ? true : false,
            project_title: response.data.settings.title,
            project_description: response.data.settings.description,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "projects") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            title: title,
            description: description,
            keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  show_all_list = () => {
    let all = this.state.copy_project_list;
    this.setState({ project_list: all });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.title && (
          <Helmet>
            <meta name="title" content={this.state.title} />
            <meta name="description" content={this.state.description} />
            <meta name="keywords" content={this.state.keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        <div class="top-space"></div>
        <div class="main_heading">
          <h1>Products</h1>
        </div>
        <section class="bluemark_product_category_list">
          <h4>Explore all categories</h4>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="list">
                  {this.state.categories.map((item, index) => {
                    return (
                      <div class="single" key={index}>
                        {/* <img src="" alt="" /> */}
                        <div className="white-block"></div>
                        <h6>{item}</h6>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_popular_products">
          <h4>Popular Products</h4>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4">
                <div class="single">
                  <div className="product_highlight">
                    <div class="offer_text">Offer Text</div>
                  </div>
                  <div class="product_name">
                    <span>Name</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="single">
                  <div className="product_highlight">
                    <div class="offer_text">Offer Text</div>
                  </div>
                  <div class="product_name">
                    <span>Name</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="single">
                  <div className="product_highlight">
                    <div class="offer_text">Offer Text</div>
                  </div>
                  <div class="product_name">
                    <span>Name</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_popular_products">
          <h4>Special Offers</h4>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6">
                <div class="single">
                  <div className="product_highlight">
                    <div class="offer_text">Offer Text</div>
                  </div>
                  <div class="product_name">
                    <span>Name</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="single">
                  <div className="product_highlight">
                    <div class="offer_text">Offer Text</div>
                  </div>
                  <div class="product_name">
                    <span>Name</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bluemark_all_products">
          <h2>All Products</h2>
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3">
                <div class="filter_list">
                  <div class="head">Product Category</div>
                  <ul>
                    <li>
                      <span>Category 01</span>
                      <Checkbox></Checkbox>
                    </li>
                    <li>
                      <span>Category 02</span>
                      <Checkbox></Checkbox>
                    </li>
                    <li>
                      <span>Category 03</span>
                      <Checkbox></Checkbox>
                    </li>
                  </ul>
                </div>
                <div class="filter_list">
                  <div class="head">Price Range</div>
                  <ul>
                    <li>
                      <span>₹99 - ₹199</span>
                      <Checkbox></Checkbox>
                    </li>
                    <li>
                      <span>₹200 - ₹399</span>
                      <Checkbox></Checkbox>
                    </li>
                    <li>
                      <span>₹400 - ₹599</span>
                      <Checkbox></Checkbox>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-9 p_list">
                <div class="row">
                  {this.state.product_list.map((item, index) => {
                    return (
                      <div class="col-lg-4" key={index}>
                        <div class="single_product">
                          <img src="./assets/img/product-image.svg" alt="" />
                          <h4 class="name">{item}</h4>
                          <a href="#" className="view_more bluemark_button">
                            View more
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <OtherSection />
        <Footer />
      </div>
    );
  }
}
