import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";
import URLs from "./utils/apis";
import axios from "axios";
import { Helmet } from "react-helmet";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact_map_link: "",
      social_links: [],
      contact1: "",
      contact2: "",
      email1: "",
      email2: "",
      contact_address: "",

      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    };
  }
  componentDidMount() {
    this.get_header_footer_settings();
    this.get_social_link();
    this.get_meta_settings();
  }
  get_meta_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_meta_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          let title = "";
          let description = "";
          let keywords = "";

          for (let item of response.data.data) {
            if (item.page == "contacts") {
              title = item.title;
              description = item.description;
              keywords = item.keywords;
            }
          }
          this.setState({
            meta_title: title,
            meta_description: description,
            meta_keywords: keywords,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_social_link = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_social_link)
      .then((response) => {
        console.log("social", response);
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            social_links: currentObj,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_header_footer_settings = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_header_footer_settings)
      .then((response) => {
        if (response.data.status == "1") {
          let currentObj = response.data.data;
          this.setState({
            contact_map_link: currentObj.contact_map_link,
            contact1: currentObj.contact1,
            contact2: currentObj.contact2,
            email1: currentObj.email1,
            email2: currentObj.email2,
            contact_address: currentObj.footer_address,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="page-container">
        {this.state.meta_title && (
          <Helmet>
            <meta name="title" content={this.state.meta_title} />
            <meta name="description" content={this.state.meta_description} />
            <meta name="keywords" content={this.state.meta_keywords} />
          </Helmet>
        )}
        <Header />
        <TopSec />
        <div class="top-space"></div>
        {/* Start Contact section  */}
        <div class="main_heading">
          <h1>Contact us</h1>
        </div>
        <div class="bluemark_contact_details">
          <img
            src="./assets/img/contact/contact-net-img.svg"
            className="contact-net-img"
          />
          <div class="container">
            <div class="row">
              <div class="col-lg-6 left">
                <div class="contact_details row">
                  <div class="col-lg-2 pl-0">
                    <img
                      src="./assets/img/contact/call.svg"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div class="col-lg-10">
                    <p>+91 88888 42745</p>
                  </div>
                </div>
                <div class="contact_details row">
                  <div class="col-lg-2 pl-0">
                    <img
                      src="./assets/img/contact/mail.svg"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div class="col-lg-10">
                    <div class="single_email">
                      <span>For enquiries within India</span>
                      <a href="mailto:sales@bluemark.in">sales@bluemark.in</a>
                    </div>
                    <div class="single_email">
                      <span>For enquiries outside India</span>
                      <a href="mailto:exports@bluemark.in">
                        exports@bluemark.in
                      </a>
                    </div>
                    <div class="single_email">
                      <span>For dealership requests</span>
                      <a href="mailto:b2b@bluemark.in">b2b@bluemark.in</a>
                    </div>
                    <div class="single_email">
                      <span>For job opportunities</span>
                      <a href="mailto:hr@bluemark.in">hr@bluemark.in</a>
                    </div>
                    <div class="single_email border-bottom-0">
                      <span>For becoming a vendor</span>
                      <a href="mailto:production@bluemark.in">
                        production@bluemark.in
                      </a>
                    </div>
                  </div>
                </div>
                <div class="contact_details row border-bottom-0">
                  <div class="col-lg-2 pl-0">
                    <img
                      src="./assets/img/contact/location.svg"
                      alt=""
                      className="icon"
                    />
                  </div>
                  <div class="col-lg-10">
                    <span>
                      Plot No. 16/4, Ramtekdi Industrial Estate, Opp. Ajanta
                      Soap Company, Hadapsar, Pune - 411013 (MH) India.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 right">
                {/* <img src="./assets/img/map.svg" /> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.65232824866!2d73.918571!3d18.499401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1e5e102bf75%3A0xa9dd7697909360d4!2s16%2F4%2C%20Ramtekdi%20Industrial%20Area%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra%20411028!5e0!3m2!1sen!2sin!4v1718539050984!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  allowfullscreen=""
                  loading="lazy"
                  style={{ borderRadius: 16 }}
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div class="bluemark_enquiry_form">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2>Send your Enquiry</h2>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Name*</label>
                      <input type="text" placeholder="Enter Your Full Name" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Subject</label>
                      <select>
                        <option value="">Select</option>
                        <option value="Test">Test</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Email*</label>
                      <input type="text" placeholder="Email Address" />
                    </div>
                    <div class="form-group">
                      <label for="">Mobile Number*</label>
                      <input type="text" placeholder="Mobile Number" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="">Message</label>
                      <textarea
                        name=""
                        id=""
                        placeholder="Message"
                        rows="5"
                        cols="50"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-lg-6 m-auto">
                    <button className="bluemark_button" type="button">
                      Submit &nbsp;
                      <box-icon name="right-arrow-alt" color="#fff"></box-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Contact section  */}

        <Footer />
      </div>
    );
  }
}
