import React, { Component } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import TopSec from "./components/TopSec";

export default class SignIn extends Component {
  render() {
    return (
      <div className="page-container">
        <Header />
        <TopSec />
        <div class="top-space"></div>
        <section class="sign_in_section">
          <h2 className="text-center">Sign In</h2>
          <div class="container">
            <div class="row">
              <div class="col-lg-5 m-auto">
                <div className="form">
                  <div class="form-group">
                    <input type="text" placeholder="Email Address" />
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Password" />
                  </div>
                  <a href="#">Forgot Password</a>
                  <button className="bluemark_button sign_in">Sign In</button>

                  <div class="account">
                    <span>Don’t have an account?</span>
                    <button className="bluemark_button sign_up">
                      Sign Up Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
