import React, { Component } from "react";
import $ from "jquery";

export default class ScreenLoader extends Component {
  componentDidMount() {
    setTimeout(() => {
      $(".loader-section").addClass("hide-loader");
    }, 3000);
  }
  render() {
    return (
      <div className="loader-section">
        <div className="inner">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={"./assets/img/logo.svg"} className="logo-img" />
            <img src={"./assets/img/loader.gif"} className="loader" />
          </div>
        </div>
      </div>
    );
  }
}
